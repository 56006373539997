import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { callBalanceOf, callTokenOfOwnerByIndex } from "../utils/function";
import {
  contractAddressPhaseOne,
  contractAddressPhaseTwo,
  contractPhaseOne,
  contractPhaseTwo,
  testAddress,
} from "../utils/constant";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  NativeSelect,
} from "@material-ui/core";
import Select from "react-select";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const MyComponent = () => <Select options={options} width="200px" />;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  formControl: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "white",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&.MuiInputBase-input": { color: "#ffffff" },
  },
  icon: {
    fill: "white",
  },
  input: {
    color: "white",
  },
}));

export default function SelectComponent(props) {
  const classes = useStyles();
  console.log("props", props);
  const {
    address,
    treeState,
    setTreeState,
    selectedCount,
    button,
    setTreeCount,
    state,
    setState,
  } = props;

  const [treeOptions, setTreeOptions] = useState([]);

  useEffect(() => {
    console.log("treeStatetreeState", treeState);
    console.log("treeState.length", treeState.length);
    if (treeState.length === 0) {
      console.log("reset");
      setTreeOptions([]);
    }
  }, [treeState]);
  console.log(state);

  useEffect(() => {
    const getData = async () => {

      const firstContractTreeCount = await contractPhaseOne.methods
        .balanceOf(address)
        .call();

      const secondContractTreeCount = await contractPhaseTwo.methods
        .balanceOf(address)
        .call();

      console.log(firstContractTreeCount, secondContractTreeCount);
      // setTreeState(dataFirst.assets.concat(dataSecond.assets));
      setTreeCount(
        parseInt(firstContractTreeCount) + parseInt(secondContractTreeCount)
      );
    };
    address && getData();
  }, [address]);

  // const handleChange = (index, checked) => {
  //   let newArr = [...treeState]; // copying the old datas array
  //   newArr[index]["checked"] = checked; // replace e.target.value with whatever you want to change it to
  //   console.log("newArr", newArr);
  //   setTreeState(newArr); // ??
  // };
  const handleChange = (e, name) => {
    setState({
      ...state,
      [name]: e.value,
    });
  };

  const renderSelectOptions = () => {
    const hasSelectedTree = [state.firstTree, state.secondTree];

    let returnData = [];

    if (treeState.length) {
      treeState.map((e, index) => {
        const { name, image_url, checked, permalink } = e;
        const hasSelected = hasSelectedTree.findIndex((e) => e === name) !== -1;

        if (!hasSelected) {
          returnData.push({ value: name, label: name });
        }
      });
    }
    console.log("returnData", returnData);
    return returnData;
  };

  useEffect(() => {
    const newTreeOptions = renderSelectOptions();
    console.log("newTreeOptions", newTreeOptions);
    setTreeOptions(newTreeOptions);
  }, [treeState, state.firstTree, state.secondTree]);

  const selectedTree = treeState.filter((e) => e.checked === true);

  const firstTreeData = treeState.filter((e) => e.name === state.firstTree);
  const secondTreeData = treeState.filter((e) => e.name === state.secondTree);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",

      borderWidth: 0,

      borderBottomWidth: 1,
      borderColor: "#f8e4ba",
      borderRadius: 0,
      boxShadow: 0,
      "&:hover": {
        borderColor: "#f8e4ba",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "#f8e4ba",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#f8e4ba",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#f8e4ba", // Custom colour
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled
    //       ? null
    //       : isSelected
    //       ? data.color
    //       : isFocused
    //       ? color.alpha(0.1).css()
    //       : null,
    //     color: isDisabled
    //       ? "#ccc"
    //       : isSelected
    //       ? chroma.contrast(color, "white") > 2
    //         ? "white"
    //         : "black"
    //       : data.color,
    //     cursor: isDisabled ? "not-allowed" : "default",

    //     ":active": {
    //       ...styles[":active"],
    //       backgroundColor:
    //         !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
    //     },
    //   };
    // },
    // input: (styles) => ({ ...styles, ...dot() }),
    // placeholder: (styles) => ({ ...styles, ...dot() }),
    // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const [value, setValue] = useState("");

  return (
    <>
      <div className={"imageSection"}>
        {firstTreeData[0] ? (
          // <a
          //   href={firstTreeData[0].permalink}
          //   target="_blank"
          //   style={{ height: 0 }}
          // >
          <img
            className={"blankTree burnTreeImage"}
            src={firstTreeData[0].image_url}
          ></img>
        ) : (
          // </a>
          <img className={"blankTree"} />
        )}
        <Select
          isSearchable={false}
          onChange={(e) => {
            handleChange(e, "firstTree");
            console.log(e);
          }}
          isDisabled={treeOptions.length === 0}
          options={treeOptions}
          styles={colourStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div style={{ width: "2%" }}></div>

      <div className={"imageSection"}>
        {secondTreeData[0] ? (
          // <a
          //   href={firstTreeData[0].permalink}
          //   target="_blank"
          //   style={{ height: 0 }}
          // >
          <img
            className={"blankTree burnTreeImage"}
            src={secondTreeData[0].image_url}
          ></img>
        ) : (
          // </a>
          <img className={"blankTree"} />
        )}

        <Select
          isSearchable={false}
          onChange={(e) => {
            handleChange(e, "secondTree");
            console.log(e);
          }}
          isDisabled={treeOptions.length === 0}
          options={treeOptions}
          styles={colourStyles}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </>
  );
}
