import Divider from "./Divider";

export default function FireworkText(props) {
  return (
    <>
      <p className={"redTitle"}>FIREWORK</p>
      <p className={"redTitleContent"}>
        {
          "No celebration ends without some beautifully fleeting fireworks! Even though BLOOM will continue with a set frequency (every 3 weeks) in the future, BURN and BUILD will not return for the inaugural BONFIRE Festival."
        }
        {/* <b style={{ fontWeight: "700" }}>{"into the Wasted Wild"}</b> */}
        <br />
        <br />
        {"We present the "}
        <b style={{ fontWeight: "700" }}>
          {"FIREWORK: a collection of 333 animated pieces scripted in Javascript, generated at mint on-chain, and meticulously created by our friend Aluan Wang"}
        </b>
        {" "}
        <a href="https://twitter.com/IOivm" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>(@IOivm on Twitter)</a>
        {", "}
        <b style={{ fontWeight: "700" }}>{"an Art Blocks Curated artist"}</b>
        {". The artist hopes to compliment the high-res art works native to both the Absurd Arboretum main collection and the BONFIRE Festival series with something that’s at once coherent in narrative yet completely different in aesthetics, "}
        <b style={{ fontWeight: "700" }}>{"reminiscent of retro 8-bit games."}</b>
        <br />
        <br />
        <b style={{ fontWeight: "700" }}>
          {"On 10/11 at 11am EST, each BUILD token holder will be able to start claiming a FIREWORK. The exclusive window for BUILDers will last for 24 Hours. Public minting will then begin on 10/12 at 12pm EST, and last for exactly 6 days, ending on 10/18."}
        </b>
        <br />
        <br />
        {"Last but not least, we sincerely hope all Festivalgoers have had some fun in the past few weeks;"}
        <b style={{ fontWeight: "700" }}>
          {" our journey continues and more festivities to come"}
        </b>
        {" in the future!"}
      </p>
    </>
  );
}
