import Divider from "./Divider";

export default function BloomText(props) {
  return (
    <>
      <p className={"redTitle"}>BLOOM: HOLIDAY SPECIAL</p>
      <p className={"redTitleContent"}>
        {
          "BLOOM was the first act of BONFIRE Festival: the ongoing event is now on its "
        }
        <b style={{ fontWeight: "700" }}>{"3rd iteration, coinciding with the holiday season! All are welcome to participate "}</b>
        this time; Absurd Arboretum tree guardians, Wasted Wild wildling
        guardians, previous BONFIRE Festival festivalgoers, and anyone who would
        love to join us to give back as this year of festivity comes to an end!
        <br />
        <br />
        {" During the window between "}
        <b style={{ fontWeight: "700" }}>
          {"December 23th at 10pm EST and December 25th at 10pm EST"}
        </b>
          {", anyone may donate 33 real life trees to "}
          <a href={"https://teamtrees.org/"} target="_blank" rel="noreferrer">
            <b className={"teamTree"}>TeamTrees</b>
          </a> to receive an animated blooming flower - a perfect, holiday-themed, addition to your collection! Let us invite all our friends and family to participate, give back, and receive a stunning FREE NFT: <b style={{ fontWeight: "700" }}>{"at once contributing to the environment and onboarding others to NFT fun this holiday!"}</b>
        <br />
        <br />
        To add to the festivity, those who donated will not only be able to mint
        BLOOM: Volume 3 for FREE (plus gas), but also enter giveaway(s)
        automatically.
        <br />
        There will be 2 raffles simultaneously:
        <br />
        <ol id="bloomOl">
          <li>
            A special artist edition 1/1 Crab from one of our creature collaborators, AKA.
            <br />
            This will be exclusively for those who also hold at least 1 Wasted Wild NFT.
          </li>
          <li>
            3 Wasted Wild NFTs will be raffled to 3 winners (1 each).
          </li>
        </ol>
      </p>
    </>
  );
}
