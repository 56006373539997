import logo from "./logo.svg";
import "./App.css";

import Onboard from "bnc-onboard";
import Notify from "bnc-notify";
import { storage } from "./utils/firebase";

import axios from "axios";

import Web3 from "web3";
import { ref, uploadBytes, listAll } from "firebase/storage";

import { useEffect, useRef, useState, useCallback } from "react";
import {
  callBalanceOf,
  getBurnAction,
  sendBloomMint,
  sendSetApprovalForAll,
  sendSwap,
} from "./utils/function";
import {
  abiFireworks,
  abiFestival,
  abiPhaseOne,
  appText,
  contractAddressFireworks,
  contractAddressFestival,
  contractAddressPhaseOne,
  contractAddressPhaseTwo,
  contractFestival,
  contractPhaseOne,
  contractPhaseTwo,
} from "./utils/constant";
import Select from "./pages/Select";
import Approval from "./pages/Approval";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Upload from "./pages/Upload";
import bottomPic_1 from "./asset/bloom_2.mp4";
import bottomPic_2 from "./asset/bloom_1.mp4";
import burnVideo_1 from "./asset/the_vault_1.gif";
import burnVideo_2 from "./asset/the_vault_2.mp4";
import firework from "./asset/firework.mp4";
import crabAka from "./asset/crab_aka.mp4";

import buildImage from "./asset/build.png";
import bloom3Img from "./asset/bloom_3.png";

import abar from "./asset/ab-ar.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BurnText from "./pages/BurnText";
import BuildText from "./pages/BuildText";
import FireworkText from "./pages/FireworkText";

import Divider from "./pages/Divider";
import BloomText from "./pages/BloomText";
const config = {
  dappId: "e5e1c635-2bb9-4797-b03b-b39364f24b0a", // [String] The API key created by step one above
  networkId: 1, // [Integer] The Ethereum network ID your Dapp uses. // rinkeby = 4
  subscriptions: {
    wallet: (wallet) => {
      window.localStorage.setItem("selectedWallet", wallet.name);
    },
  },
};

const onboard = Onboard(config);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const treePhaseTextArray = ["Origin :", "Diaspora :", "Vault :", "Rebirth :"];

const liveControl = {
  bloom: true,
  burn: false,
  build: false,
  fireworks: {
    claiming: false,
    minting: false,
  },
};

function App() {
  const [onboardState, setOnboardState] = useState();
  const [treeState, setTreeState] = useState([]);
  const [imageAsFile, setImageAsFile] = useState("");
  const [accountArray, setAccountArray] = useState([]);
  const [imageRatio, setImageRatio] = useState(1);
  const [burnText, setBurnText] = useState("Approve for Arbo Contract 1");
  const [state, setState] = useState({
    firstTree: "",
    secondTree: "",
  });
  const [remaining, setRemaining] = useState("100");
  const [loading, setLoading] = useState(false);
  const [testMint, setTestMint] = useState(false);
  const [treeCount, setTreeCount] = useState(0);

  const classes = useStyles();

  const handleClose = () => {
    setLoading(false);
  };
  const handleToggle = () => {
    setLoading(!loading);
  };
  const inputFile = useRef(null);
  const imageRef = useRef(null);
  console.log("treeState", treeState);
  const address = onboardState?.address;
  useEffect(() => {
    if (!imageAsFile) return;
    var reader = new FileReader();
    reader.readAsDataURL(imageAsFile);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        setImageRatio(this.height / this.width);
      };
    };
  }, [imageAsFile]);

  const [password, setPassword] = useState("");

  useEffect(() => {
    const getBloomList = async () => {
      const listRef = ref(storage, "bloom_volume_2/");
      const bloomList = await listAll(listRef);
      let tempAccountArray = [];
      bloomList.items.forEach((itemRef) => {
        tempAccountArray.push(itemRef._location.path);
      });
      setAccountArray(tempAccountArray);
    };
    getBloomList();
  }, []);

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];

    setImageAsFile((imageFile) => image);
  };

  const handleFireBaseUpload = (e) => {
    e.preventDefault();
    if (imageAsFile === "") {
      alert("Please select an image");
      setImageAsFile("");
      return;
    }

    const fileName = "bloom_volume_3/" + address;
    handleToggle();

    if (accountArray.findIndex((e) => e === fileName) !== -1) {
      if (
        window.confirm(
          "Have Already Uploaded. Do you want to sumbit image again?"
        )
      ) {
        const storageRef = ref(storage, fileName);
        uploadBytes(storageRef, imageAsFile).then((snapshot) => {
          handleClose();

          alert("Submit successfully");
        });
      } else {
        return;
      }
    } else {
      const storageRef = ref(storage, fileName);
      uploadBytes(storageRef, imageAsFile).then((snapshot) => {
        handleClose();
        alert("Submit successfully");
      });
    }
  };

  const [isEligible, setIsEligible] = useState(false);
  const [isEligiBuild, setIsEligiBuild] = useState(false);
  const bloomMint = async () => {
    if (!address) {
      alert("Please Connect Wallet First!");
      return;
    }

    if (!isEligible) {
      return;
    }

    let web3;

    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else {
      web3 = new Web3(
        "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
      );
    }

    const contractFestivalTest = new web3.eth.Contract(
      abiFestival,
      contractAddressFestival
    );

    console.log("address", address);
    console.log("contractFestivalTest", contractFestivalTest.methods);

    contractFestivalTest.methods
      .bloom()
      .send({ from: address })
      .then((e) => {
        console.log(e);
      });
  };

  const buildMint = async () => {
    // setLoading(true);

    // wallet verification
    if (!address) {
      alert("Please Connect Wallet First!");
      return;
    }
    //tree verification
    let zeroCount = 0;
    treePhaseArray.map((e) => {
      e === 0 && zeroCount++;
    });
    if (zeroCount >= 4) {
      alert("Need to meet the requirement");
      return;
    }
    // eligToBuild verification
    if (!isEligiBuild) {
      return;
    }
    // main
    let web3;

    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else {
      web3 = new Web3(
        "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
      );
    }

    const contractFestivalTest = new web3.eth.Contract(
      abiFestival,
      contractAddressFestival
    );

    contractFestivalTest.methods
      .build()
      .send({
        from: address,
        value: web3.utils.toWei("0.07", "ether"),
      })
      .then((e) => {
        console.log(e);
      });
  };

  const [remainingFireworks, setRemainingFireworks] = useState(333);
  useEffect(() => {
    const fetchRemainingFireworks = async () => {
      let web3;

      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(
          "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
        );
      }

      const contractFireworks = new web3.eth.Contract(
        abiFireworks,
        contractAddressFireworks
      );

      const totalSupply = await contractFireworks.methods.totalSupply().call();
      setRemainingFireworks(333 - totalSupply);
    }

    fetchRemainingFireworks();
  }, [])

  const fireworksMint = useCallback(async () => {
    if (!liveControl.fireworks.minting || remainingFireworks === 0) {
      return;
    }
    // setLoading(true);

    // wallet verification
    if (!address) {
      alert("Please Connect Wallet First!");
      return;
    }
    // main
    let web3;

    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else {
      web3 = new Web3(
        "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
      );
    }

    const contractFireworks = new web3.eth.Contract(
      abiFireworks,
      contractAddressFireworks
    );

    contractFireworks.methods
      .mint()
      .send({ from: address, value: web3.utils.toWei("0.03", "ether") })
      .then((e) => {
        console.log(e);
      });
  }, [address, liveControl.fireworks.minting, remainingFireworks]);

  const [treePhaseArray, setTreePhaseArray] = useState([0, 0, 0, 0]);

  useEffect(() => {
    if (!address) return;

    const getEligibleBloom = async () => {
      let web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(
          "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
        );
      }
      const contractFestivalTest = new web3.eth.Contract(
        abiFestival,
        contractAddressFestival
      );
      const result = await contractFestivalTest.methods
        .eligToBloom(address)
        .call();
      console.log("isEligible", result);
      setIsEligible(result);
    };

    const getEligibleBuild = async () => {
      let web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(
          "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
        );
      }
      const contractFestivalTest = new web3.eth.Contract(
        abiFestival,
        contractAddressFestival
      );
      const result = await contractFestivalTest.methods
        .eligToBuild(address)
        .call();
      console.log("eligiBuild", result);
      setIsEligiBuild(result);
    };
    const getTreeAndClassify = async () => {
      setLoading(true);

      let newTreeIDArray = [];

      const balancePhaseOne = await contractPhaseOne.methods
        .balanceOf(address)
        .call();
      for (let i = 0; i < balancePhaseOne; i++) {
        const tokenID = await contractPhaseOne.methods
          .tokenOfOwnerByIndex(address, i)
          .call();
        newTreeIDArray.push(parseInt(tokenID));
      }

      const balancePhaseTwo = await contractPhaseTwo.methods
        .balanceOf(address)
        .call();
      for (let i = 0; i < balancePhaseTwo; i++) {
        const tokenID = await contractPhaseTwo.methods
          .tokenOfOwnerByIndex(address, i)
          .call();
        newTreeIDArray.push(parseInt(tokenID));
      }
      console.log("balancePhaseOne", balancePhaseOne);
      console.log("balancePhaseTwo", balancePhaseTwo);
      // classification
      let newPhaseCountArray = [0, 0, 0, 0];
      console.log("newTreeIDArray", newTreeIDArray);

      newTreeIDArray.map((e) => {
        if (e <= 885) {
          newPhaseCountArray[0] += 1;
        } else if (e <= 1769) {
          newPhaseCountArray[1] += 1;
        } else if (e <= 1869) {
          newPhaseCountArray[2] += 1;
        } else if (e <= 3391) {
          newPhaseCountArray[3] += 1;
        }
      });

      setTreePhaseArray(newPhaseCountArray);
      setLoading(false);
    };
    getEligibleBloom();

    getTreeAndClassify();
    getEligibleBuild();
  }, [address]);

  console.log("treePhaseArray", treePhaseArray);
  const onLoad = async () => {
    // ... initialize onboard

    const notify = Notify({
      dappId: "e5e1c635-2bb9-4797-b03b-b39364f24b0a", // [String] The API key created by step one above
      networkId: 1, // [Integer] The Ethereum network ID your Dapp uses.
    });
    // get the selectedWallet value from local storage
    const previouslySelectedWallet =
      window.localStorage.getItem("selectedWallet");

    if (previouslySelectedWallet === "undefined") return;
    await onboard.walletSelect(previouslySelectedWallet);
    await onboard.walletCheck();
    const currentState = await onboard.getState();
    console.log(currentState);
    setOnboardState(currentState);
  };

  const connectWallet = async (e) => {
    e.preventDefault();

    const isSelect = await onboard.walletSelect("MetaMask");
    if (!isSelect) {
      return;
    }
    const isCheck = await onboard.walletCheck();
    if (!isCheck) {
      return;
    }

    const currentState = await onboard.getState();
    setOnboardState(currentState);
  };

  const disConnectWallet = async (e) => {
    e.preventDefault();
    await onboard.walletReset();
    setTreePhaseArray([0, 0, 0, 0]);
    setImageAsFile("");
    setOnboardState(undefined);
    setTreeState([]);
  };

  console.log("treeState", treeState);

  const selectedTree = treeState.filter((e) => e.checked === true);

  const onButtonClick = () => {
    const checkTrees = () => {
      if (!onboardState) {
        alert("Please Connect Wallet First!");
        return false;
      } else {
        return true;
      }
    };

    if (!checkTrees()) {
      return;
    }
    inputFile.current.click();
  };

  const handleBurn = async () => {
    console.log(state);
    const firstTreeData = treeState.filter((e) => e.name === state.firstTree);
    const secondTreeData = treeState.filter((e) => e.name === state.secondTree);

    if (!(firstTreeData.length && secondTreeData.length)) {
      alert("Connect wallet and Choose Trees first.");
      return;
    }

    console.log("firstTreeData", firstTreeData[0]);
    console.log("secondTreeData", secondTreeData[0]);

    const hasPhaseOne =
      firstTreeData[0].asset_contract.address === contractAddressPhaseOne ||
      secondTreeData[0].asset_contract.address === contractAddressPhaseOne;
    const hasPhaseTwo =
      firstTreeData[0].asset_contract.address === contractAddressPhaseTwo ||
      secondTreeData[0].asset_contract.address === contractAddressPhaseTwo;

    console.log("hasPhaseOne", hasPhaseOne);
    console.log("hasPhaseTwo", hasPhaseTwo);

    if (hasPhaseOne === false && hasPhaseTwo === true && burnText !== "BURN") {
      const result = await sendSetApprovalForAll(contractPhaseTwo, address);
      setBurnText("BURN");
      return;
    }

    if (hasPhaseOne && burnText === "Approve for Arbo Contract 1") {
      const result = await sendSetApprovalForAll(contractPhaseOne, address);
      console.log("result", result);
      if (hasPhaseTwo) setBurnText("Approve for Arbo Contract 2");
      else setBurnText("BURN");
      return;
    } else if (hasPhaseTwo && burnText === "Approve for Arbo Contract 2") {
      const result = await sendSetApprovalForAll(contractPhaseTwo, address);
      console.log("result", result);
      setBurnText("BURN");
      return;
    } else {
      const swapTokenIds = [
        parseInt(firstTreeData[0].token_id),
        parseInt(secondTreeData[0].token_id),
      ];

      console.log("swapTokenIds", swapTokenIds);

      await sendSwap(swapTokenIds, contractFestival, address);
    }
  };

  useEffect(() => {
    const getResult = async () => {
      const result = await contractFestival.methods.remainingSupply().call();
      console.log("result", result);

      setRemaining(result);
    };

    getResult();
  }, []);

  const [inputCheckValue, setInputCheckValue] = useState(null);
  const handleCheckInputChange = useCallback((e) => {
    setInputCheckValue(e.target.value)
  }, []);
  const checkIsClaimed = useCallback(async () => {
    if (!liveControl.fireworks.claiming && !liveControl.fireworks.minting) {
      return;
    }

    let res = false;
    if (inputCheckValue >= 357 && inputCheckValue <= 536) {
      // main
      let web3;

      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(
          "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
        );
      }

      const contractFireworks = new web3.eth.Contract(
        abiFireworks,
        contractAddressFireworks
      );

      res = !(await contractFireworks.methods
        ._buildTokenClaimed(inputCheckValue)
        .call())
    }
    alert(`Token ID: ${inputCheckValue} is ${res ? 'eligibile' : 'not eligibile'}`);
  }, [inputCheckValue, liveControl.fireworks.claiming, liveControl.fireworks.minting]);

  const [inputClaimValue, setInputClaimValue] = useState(null);
  const handleClaimInputChange = useCallback((e) => {
    setInputClaimValue(e.target.value)
  }, []);
  const fireworksClaim = useCallback(async () => {
    if (!liveControl.fireworks.claiming || remainingFireworks === 0) {
      return;
    }
    // setLoading(true);

    // wallet verification
    if (!address) {
      alert("Please Connect Wallet First!");
      return;
    }
    // main
    let web3;

    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else {
      web3 = new Web3(
        "https://mainnet.infura.io/v3/8b56b46cf9024ed98e0879fad7d172e4"
      );
    }

    const contractFireworks = new web3.eth.Contract(
      abiFireworks,
      contractAddressFireworks
    );

    contractFireworks.methods
      .claim(inputClaimValue)
      .send({ from: address })
      .then((e) => {
        console.log(e);
      });
  }, [address, inputClaimValue, liveControl.fireworks.claiming, remainingFireworks]);

  return (
    <div className="App">
      <AppBar
        elevation={0}
        position="sticky"
        style={{ backgroundColor: "#0c1c1c" }}
      >
        <Toolbar>
          <a href={"https://ab-ar.art/"} target="_blank" rel="noreferrer">
            <img className={"abarLogo"} src={abar} />
          </a>
        </Toolbar>
      </AppBar>
      <p className={"redTitle"}>Welcome to BONFIRE Festival</p>
      <p className={"redTitleContent"}>
        {"The Inaugural "}
        <b style={{ fontWeight: "700" }}>{" BONFIRE Festival "}</b>
        hosted by Capsule Vault will take place in Absurd Arboretum in the late
        Summer of the year 2021. To prepare ourselves for what’s to come in this
        journey we’ve embarked on, within a fabricated universe of flourishing
        trees and of other mystical beings yet to have spawned, we must first
        celebrate all that we’ve achieved together as a community, around a
        bonfire, in this ever changing and exciting space of tokens,
        synthesizing the digital and the physical!
        <br />
        <br />
        There will be three main events over the entire duration of the
        festivity:
        <b style={{ fontWeight: "700" }}>{" Bloom, Burn, and Build"}</b>
        {". These events will be rolled out "}
        <b style={{ fontWeight: "700" }}>successively </b>
        {"as the festival unfolds. "}
        <b style={{ fontWeight: "700" }}>
          All activities require Ab-Ar tree artifacts to participate
        </b>
        {
          ", as these trees are not only generative digital art and living physical trees, but also our membership tokens - "
        }
        <b style={{ fontWeight: "700" }}>a trinity!</b>
        {
          " Each event will require different amounts of trees --some more and some less-- and may include other actions to be made in order to obtain additional and/or augmented artifacts."
        }
        <br />
        <br />
        {
          "BLOOM is the first act of the BONFIRE festival. To bloom is to grow; nurtured with care. In Absurd Arboretum, we grow not only in strength, but with beauty and vigor; "
        }
        <b style={{ fontWeight: "700" }}>
          {" a growth developed with utmost intensity and glory: flowering!"}
        </b>
        <br />
        <br />
        {
          "BURN is the second act of the BONFIRE Festival. To burn is to deconstruct; consumed in flame, often preceded by a state of flourishing. In Absurd Arboretum, we deconstruct in order to recreate! "
        }
        <b style={{ fontWeight: "700" }}>
          New lives will be born and memories will be preserved after the burn.
        </b>
        <br />
        <br />
        {
          "BUILD is the third and final act of the BONFIRE festival. To build is to construct, strengthened by assembly. In Absurd Arboretum, we construct in order to elevate and expand! Structures will rise to provide shelter for the adventurers to call home and "
        }
        <b style={{ fontWeight: "700" }}>
          prepare for the next epic journey into the Wild
        </b>
        {" that lays beyond the Arboretum."}
      </p>

      <>
        <Divider />
        <BloomText />
        {/* <Approval /> */}
        <div className={"container"}>
          <div className={"section buildSection"}>
            <p className={"bloomTitle"}>BLOOM: Volume 3, 12/23 - 12/25</p>
            <p className={"bloomContent"}>
              <b style={{ fontWeight: "700" }}>
                Donation required for this round: 33
              </b>
              <br />
              <br />
              Please connect your wallet;
              <br />
              upload and submit donation screenshot.
              <br />
              {"Please donate to "}
              <a
                href={"https://teamtrees.org/"}
                target="_blank"
                rel="noreferrer"
              >
                <b className={"teamTree"}>TeamTrees</b>
                {" (read above),"}
                <br />
              </a>
              <br />
              Minting will be opened and announced on 12/25.
            </p>
          </div>

          <div className={"section buildSection"}>
            <div className={"limitImage"}>
              {imageAsFile ? (
                <img
                  className={"previewImage"}
                  ref={imageRef}
                  src={imageAsFile ? URL.createObjectURL(imageAsFile) : null}
                />
              ) : (
                <div
                  className={"previewImage"}
                  style={{ height: 180, backgroundColor: "#0c1c1c" }}
                ></div>
              )}
            </div>
            {/* <p className={"currentTree"}>Current Trees: {treeCount}</p> */}

            <button
              disabled={!liveControl.bloom}
              className={"connectButton"}
              onClick={(e) => {
                onboardState === undefined
                  ? connectWallet(e)
                  : disConnectWallet(e);
              }}
            >
              {onboardState === undefined ? "Connect" : "Disconnect"}
            </button>
            <input
              type="file"
              id="file"
              inputProps={{ accept: "image/*" }}
              ref={inputFile}
              style={{ display: "none" }}
              onChange={handleImageAsFile}
            />
            {testMint ? (
              <button
                disabled={!liveControl.bloom}
                className={"connectButton"}
                onClick={imageAsFile ? handleFireBaseUpload : onButtonClick}
              >
                {imageAsFile ? "Submit" : "Select to Upload"}
              </button>
            ) : (
              <button
                disabled={!liveControl.bloom || !isEligible}
                className={"connectButton"}
                onClick={bloomMint}
              >
                {isEligible ? "Mint" : "Minted or Ineligible"}
              </button>
            )}
          </div>
        </div>
        <p className={"redTitle"}>How to participate:</p>
        <p className={"redTitleContent"}>
          Please connect your wallet above, and you will be able to select and
          upload a screenshot.
          <b style={{ fontWeight: "700" }}>
            {" Please upload the screenshot of your donation "}
          </b>
          to TeamTrees.
          <br />
          <br />
          When donating on TeamTree’s site, please enter
          <b style={{ fontWeight: "700" }}>
            {" “Absurd Arboretum” in “DISPLAY NAME” "}
          </b>
          and your
          <b style={{ fontWeight: "700" }}>
            {" wallet address that holds the trees in “MESSAGE” (Do not worry if your full address may be clipped by the message box by 2 digits - this will NOT be a problem.)"}
          </b>
          . Those who failed to follow these steps may encounter problems as we
          will verify the donation with the information provided! In the “TEAM”
          section, please feel free to enter your own desired text such as your
          Discord, Twitter, or real name!
          <br />
          <br />
          After the closing of the 3-day donation period, the team will go
          through a process of verification and minting of the BLOOM token will
          then be opened for all those who submitted successfully.
        </p>
        <div className={"imageContainer"} style={{ marginTop: 50 }}>
          <div className={"imageSection"}>
            <img
              style={{ width: "100%" }}
              src={bloom3Img}
            />
          </div>
          <div style={{ width: "2%" }}></div>
          <div className={"imageSection"}>
            <video
              style={{ width: "100%" }}
              src={crabAka}
              autoPlay
              muted
              loop
            />
          </div>
        </div>
        <div className={"imageContainer"} style={{ marginTop: 50 }}>
          <div className={"imageSection"}>
            <video
              style={{ width: "100%" }}
              src={bottomPic_2}
              autoPlay
              muted
              loop
            />
          </div>
          <div style={{ width: "2%" }}></div>
          <div className={"imageSection"}>
            <video
              style={{ width: "100%" }}
              src={bottomPic_1}
              autoPlay
              muted
              loop
            />
          </div>
        </div>
      </>

      <>
        <Divider />
        <FireworkText />
        <div className={"container"}>
          <div className={"section buildSection"}>
            <p className={"bloomTitle buildTitle fireworkTitle"}>FIREWORK: 10/11 - 10/18</p>
            <p className={"bloomContent"}>
              <b style={{ fontWeight: "700" }}>
                Generated on-chain at mint!
                <br />
                Maximum supply of 333,
                <br />
                with utility revealed soon.
                <br />
                Please connect your wallet.
              </b>
            </p>
            <button
              disabled={!liveControl.fireworks.claiming && !liveControl.fireworks.minting}
              className={"connectButton"}
              style={{ minHeight: 35, marginTop: "36px" }}
              onClick={(e) => {
                onboardState === undefined
                  ? connectWallet(e)
                  : disConnectWallet(e);
              }}
            >
              {onboardState === undefined ? "Connect" : "Disconnect"}
            </button>
            <div className={"bloomContent"} style={{fontWeight: '400', marginTop: '8px'}}>Please first connect to interact</div>
            <div style={{
              marginTop: "36px",
              marginBottom: "36px",
              boxSizing: "border-box",
              backgroundColor: "rgba(222, 208, 206, 0.24)",
              height: "2px",
              width: "80%",
              marginLeft: "10px",
              marginRight: "10px"
            }} />
            <button
              disabled={!liveControl.fireworks.claiming && !liveControl.fireworks.minting}
              style={{ minHeight: 35, backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'dashed #f8e4ba 1px' }}
              className={"connectButton"}
            >
              <span>#</span>
              <input value={inputCheckValue} onChange={handleCheckInputChange} type="number" style={{ backgroundColor: 'transparent', border: 'none', borderBottom: 'solid #a51080 1px', color: '#a51080', fontFamily: 'inherit', textAlign: 'center' }}/>
              <span onClick={checkIsClaimed}>{" Check"}</span>
            </button>
            <div className={"bloomContent"} style={{fontWeight: '400', marginTop: '8px'}}><b style={{fontWeight: '700'}}>Enter BUILD tokenID</b> to check eligibility</div>
          </div>

          <div
            className={"section buildSection"}
            style={{ justifyContent: "flex-end" }}
          >
            <p className={"bloomTitle buildTitle fireworkTitle"} style={{ color: 'transparent' }}>FIREWORK: 10/8 - 10/15</p>
            <p className={"bloomContent"}>
              <b style={{ fontWeight: "700" }}>
                Each BUILDer may claim 1 for free;
                <br />
                Everyone may mint at 0.03 ETH.
                <br />
                You may check if a BUILD token
                <br />
                has been used to CLAIM below.
              </b>
            </p>
            <button
              disabled={!liveControl.fireworks.minting}
              style={{ minHeight: 35, marginTop: "36px" }}
              className={"connectButton"}
              onClick={fireworksMint}
            >
              <a href="https://opensea.io/collection/arbofireworks/" target="_blank" rel="noreferrer" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ fontSize: 16 }}>SOLD OUT, purchase on OpenSea</span></a>
            </button>
            <div className={"bloomContent"} style={{fontWeight: '400', marginTop: '8px'}}>{`${remainingFireworks}/333 left, 0.03 ETH each, 1 token per tx`}</div>
            <div style={{
              marginTop: "36px",
              marginBottom: "36px",
              boxSizing: "border-box",
              backgroundColor: "transparent",
              height: "2px",
              width: "100%",
              marginLeft: "10px",
              marginRight: "10px"
            }} />
            <button
              disabled={!liveControl.fireworks.claiming}
              style={{ minHeight: 35, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              className={"connectButton"}
            >
              <a href="https://opensea.io/collection/arbofireworks/" target="_blank" rel="noreferrer" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ fontSize: 16 }}>SOLD OUT, purchase on OpenSea</span></a>
            </button>
            <div className={"bloomContent"} style={{fontWeight: '400', marginTop: '8px'}}><b style={{fontWeight: '700'}}>Enter BUILD tokenID</b> to claim once</div>
          </div>
        </div>
        <p className={"redTitle"}>How to participate:</p>
        <p className={"redTitleContent"}>
          {"Please connect your wallet above. If you are a BUILD token holder, you will be able to mint a FIREWORK "}
          <b style={{fontWeight: '700'}}>for free ONCE</b>
          {": please do so by "}
          <b style={{fontWeight: '700'}}>
            entering your BUILD token ID into the underlined blank area and click on the CLAIM button.
          </b>
          <br />
          <br />
          <b style={{fontWeight: '700'}}>
            Please note that you will be able to find the token ID as the last 3 digits in the URL of the OpenSea page, or under the “Details” tab on the same OpenSea page.
          </b>
          {" "}
          For example, for the BUILD token linked <a href="https://opensea.io/assets/0x9ecc667b19f36c712e99f600b8909f6b82dd3773/357" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>here</a>, the token ID to enter would be 357.
          <br />
          <br />
          You may check if a BUILD token has already been used to claim a FIREWORK by entering the token ID into the CHECK section.
          <br />
          <br />
          For those who do not own a BUILD token, you may also participate by minting at the cost of 0.03 ETH per token. There’s a <b style={{ fontWeight: '700' }}>1 token per transaction limit</b>, mainly to prevent anyone from minting a large amount with a single transaction, and thus giving more opportunities to everyone.
        </p>
        <div className={"imageContainer"} style={{ marginTop: 50 }}>
          <div style={{ width: "20.5%" }}></div>
          <div className={"imageSection"}>
            <video
              src={firework}
              autoPlay
              muted
              loop
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ width: "20.5%" }}></div>
        </div>
      </>

      <>
        <Divider />
        <BuildText />
        <div className={"container"}>
          <div className={"section buildSection"}>
            <p className={"bloomTitle buildTitle"}>BUILD: 9/25 - 10/2</p>
            <p className={"bloomContent"}>
              <b style={{ fontWeight: "700" }}>for all Tree Guardians!</b>
              <br />
              Please connect your wallet to verify.
              <br />
              Mint cost: 0.07 ETH each;
              <br />
              maximum 1 token per wallet.
            </p>
          </div>

          <div
            className={"section buildSection"}
            style={{ justifyContent: "flex-end" }}
          >
            {/* <div className={"phaseContainer"}>
              {treePhaseArray.map((e, index) => {
                return (
                  <div className={"phaseSection"}>
                    <p style={{ fontSize: 20, color: "#0c1c1c" }}>
                      <b>{treePhaseTextArray[index]}</b>
                      <br />

                      <b>
                        {e}
                        {" trees"}
                      </b>
                    </p>
                  </div>
                );
              })}
            </div> */}

            <button
              disabled={!liveControl.build}
              className={"connectButton"}
              style={{ minHeight: 35 }}
              onClick={(e) => {
                onboardState === undefined
                  ? connectWallet(e)
                  : disConnectWallet(e);
              }}
            >
              {onboardState === undefined ? "Connect" : "Disconnect"}
            </button>

            <button
              disabled={!liveControl.build}
              style={{ minHeight: 35 }}
              className={"connectButton"}
              onClick={buildMint}
            >
              <a href="https://opensea.io/collection/arbofest/" target="_blank" rel="noreferrer" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ fontSize: 16 }}>SOLD OUT, purchase on OpenSea</span></a>
            </button>
          </div>
        </div>
        <p className={"redTitle"}>How to participate:</p>
        <p className={"redTitleContent"}>
          {"Please connect your wallet above. "}
          <b style={{ fontWeight: "700" }}>
            {
              "If you meet the requirement of holding at least 1 tree, you will be able to mint a BUILD token"
            }
          </b>
          <b>{"."}</b>
          {/* <br />
          <br />
          {
            "You should be able to find which “Phase” your tree is from under the Properties tab on the OpenSea page of the tree. If you don’t see a “Phase” trait, please "
          }
          <b style={{ fontWeight: "700", fontStyle: "oblique" }}>
            {"refresh metadata"}
          </b>
          {
            " at the top right of the page (by clicking on a button with the refresh symbol). There are a total of 4 Phases: the Origin (#1-#885), the Diaspora (#886-#1769), the Rebirth (#2223-#3391), and the Vault (#1770-#1869), corresponding to when these trees were spawned and released over time."
          } */}
        </p>
        <div className={"imageContainer"} style={{ marginTop: 50 }}>
          <div style={{ width: "20.5%" }}></div>

          <div className={"imageSection"}>
            <img style={{ width: "100%" }} src={buildImage} />
          </div>
          <div style={{ width: "20.5%" }}></div>
        </div>
      </>

      <Divider />
      <BurnText />
      {/* <Approval /> */}
      <div className={"burnContainer"}>
        <div className={"section burn buildSection"}>
          <p className={"bloomTitle burnTitle"}>BURN: 9/6 - 9/13</p>
          <p className={"bloomContent"} style={{ marginTop: 20 }}>
            <br />
            for Tree Guardians with 2 or more trees
            <br />
            <br />
            Please connect your wallet to verify;
            <br />
            Select a tree from each dropdown to burn,
            <br />
            Click on BURN to confirm and submit.
            <br />
            Once submitted, 2 new trees will appear in your wallet
            <br />
            (when the transaction completes)!
          </p>
          <p className={"remain"} style={{ visibility: "hidden" }}>
            100/100 BURN actions remaining
          </p>

          <button
            disabled={!liveControl.burn}
            className={"connectButton"}
            onClick={(e) => {
              onboardState === undefined
                ? connectWallet(e)
                : disConnectWallet(e);
            }}
          >
            {onboardState === undefined ? "Connect" : "Disconnect"}
          </button>
        </div>

        <div className={"section burn buildSection"}>
          <div className={"burnlimitImage removeBottom"}>
            <Select
              state={state}
              treeCount={treeCount}
              setTreeCount={setTreeCount}
              setState={setState}
              {...onboardState}
              treeState={liveControl.bloom ? treeState : []}
              setTreeState={setTreeState}
              selectedCount={selectedTree.length}
            />
          </div>
          <p className={"remain"}>{0}/100 BURN actions remaining</p>
          <button
            disabled={!liveControl.bloom}
            className={"connectButton"}
            onClick={handleBurn}
          >
              <a href="https://opensea.io/collection/arbofest/" target="_blank" rel="noreferrer" style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ fontSize: 16 }}>SOLD OUT, purchase on OpenSea</span></a>
          </button>
        </div>
      </div>
      <p className={"redTitle"}>How to participate:</p>
      <p className={"redTitleContent"}>
        {
          "Please connect your wallet above. If you meet the requirement of holding at least 2 trees, you will be able to select a tree from each of the two dropdown menus to be burnt. "
        }
        <b style={{ fontWeight: "700" }}>
          Once burnt, the unrevealed tree from the Vault will be transferred to
          your wallet and the holographic tree minted simultaneously!
        </b>{" "}
        The trees from the Vault will be{" "}
        <b style={{ fontWeight: "700" }}>
          revealed when BURN ends or when all 100 burning acts complete
        </b>
        , whichever happens first.
        <br />
        <br />
        Please note that for the next act of the Festival, BUILD, Festivalgoers
        will also need to be holding a few trees in order to participate, so
        <b style={{ fontWeight: "700" }}>
          {
            " please make your own judgement if you’re willing to burn 2 trees now. "
          }
        </b>
        Only 1 out of the 2 new trees received will be counted towards your
        holdings in BUILD and the rest of the BLOOM events, as the holographic
        ones live in the separate Festival collection!
      </p>
      <div className={"imageContainer"} style={{ marginTop: 30 }}>
        <div className={"imageSection"}>
          <img style={{ width: "100%" }} src={burnVideo_1} />
        </div>
        <div style={{ width: "2%" }}></div>
        <div className={"imageSection"}>
          <video
            style={{ width: "100%" }}
            src={burnVideo_2}
            autoPlay
            muted
            loop
          />
        </div>
      </div>

      <div style={{ paddingBottom: 200 }}></div>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <div className={"testButtonContainer"}>
        <button disabled
          className={"connectButton"}
          onClick={() => {
            setTestMint(true);
          }}
        >
          Change to Test Mint
        </button>
      </div> */}
    </div>
  );
}

export default App;
