import Divider from "./Divider";

export default function BurnText(props) {
  return (
    <>
      
      <p className={"redTitle"}>BURN</p>
      <p className={"redTitleContent"}>
        {
          "BURN is the second act of the Festival, during which we gather around the Bonfire at the center of the Arboretum and deconstruct to make room for further growth. New lives will be born and memories will be preserved after the burning ritual. In order to participate, the Festivalgoer "
        }
        <b style={{ fontWeight: "700" }}>
          {"must be a guardian of at least 2 Ab-Ar trees."}
        </b>
        <br />
        <br />
        <b style={{ fontWeight: "700" }}>
          {" The week-long event will begin on 9/6 at 11am EST and end on 9/13"}
        </b>
        {" at the same time. "}
        <b style={{ fontWeight: "700" }}>
          {
            "2 existing digital trees shall be selected to be burnt to receive 2 others: "
          }
        </b>
        {
          "one of them will be a new tree spawned from the Vault, within the main collection of 3,333 yet unminted previously; and the other, "
        }
        <b style={{ fontWeight: "700" }}>{"a holographic cache,"}</b>
        {
          " an entity that has yet to exist. There will only ever be a total of 100 burning actions available during the first Festival, on a first come first serve basis."
        }
        <br />
        <br />
        {"Instead of 100, however, there are only 99 holographic trees, as "}
        <b style={{ fontWeight: "700" }}>
          {"one of them turned completely metallic and machinic,"}
        </b>
        {
          " forged under the flame and eventually evolved into a new type of self-sustaining life form, nurtured by solar energy. It will be a 1 of 1."
        }
      </p>
    </>
  );
}
