import Notify from "bnc-notify";
import { contractAddressFestival } from "./constant";

export const callBalanceOf = async (contract) => {
  const balance = await contract.methods
    .balanceOf("0x9624585a386ae2d01aeac61fc6653b5b3591132d")
    .call();
  return balance;
};

export const callTokenOfOwnerByIndex = async (contract, index) => {
  const tokenID = await contract.methods
    .tokenOfOwnerByIndex("0x9624585a386ae2d01aeac61fc6653b5b3591132d", index)
    .call();
  return tokenID;
};

export const sendSetApprovalForAll = async (contract, callerAddress) => {
  console.log(contract.methods);
  const result = await contract.methods
    .setApprovalForAll(contractAddressFestival, true)
    .send({ from: callerAddress });
  console.log(result);
  return result;
};

export const sendSwap = async (swapTokenIds, contract, callerAddress) => {
  console.log(contract.methods);
  const result = await contract.methods
    .swap(swapTokenIds)
    .send({ from: callerAddress });

  console.log(result);
  return result;
};

export const getGasPrice = async () => {
  const data = await fetch(
    "https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=5787BRXHE4PA4772S3CAIXGFZ9V6WQTJ2Y"
  );

  const dataJson = await data.json();

  return parseInt(dataJson.result.FastGasPrice);
};

export const getBurnAction = async (contract) => {
  console.log(contract.methods);
  const result = await contract.methods.remainingSupply().call();

  console.log("result", result);
  return result;
};

export const sendBloomMint = async (contract, callerAddress) => { 
  console.log("Start Bloom");

  const result = await contract.methods.bloom().call();
  console.log(result);

  return result;
};
