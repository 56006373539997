import Divider from "./Divider";

export default function BuildText(props) {
  return (
    <>
      <p className={"redTitle"}>BUILD</p>
      <p className={"redTitleContent"}>
        {
          "BUILD is the third and final act of the Festival. During the week-long event, we will prepare ourselves for a grand adventure that lays beyond the Arboretum, "
        }
        <b style={{ fontWeight: "700" }}>{"into the Wasted Wild"}</b>
        {". As a "}
        <b style={{ fontWeight: "700" }}>{"guardian of an Ab-Ar tree"}</b>
        {", the Festivalgoer will be able to mint the exclusive "}
        <b style={{ fontWeight: "700" }}>{"BUILD token"}</b>
        {", limited to "}
        <b style={{ fontWeight: "700" }}>{"1 mint per wallet."}</b>
        <br />
        <br />
        {
          "This token is not only a beautiful piece of art that visually manifests the haven we have built together for the mythical trees in the Arboretum, but "
        }
        <b style={{ fontWeight: "700" }}>
          {
            "also a special emblem crafted to reward all the early supporters, allowing them to mint Wasted Wild tokens early and without gas war"
          }
        </b>
        {
          " in the very near future. In addition, BUILD token holders will have "
        }
        <b style={{ fontWeight: "700" }}>
          {"additional benefits in the near future"}
        </b>
        {", including but not limited to exclusive giveaways!"}
        <br />
        <br />
        <b style={{ fontWeight: "700" }}>
          {"The event will begin on 9/25 at 11am EST and end on 10/2"}
        </b>
        {
          " at the same time. Doubling as a “mint pass” for Chapter 2 of the Capsule Vault Trilogy, the BUILD token will cost "
        }
        <b style={{ fontWeight: "700" }}>{"0.07 ETH"}</b>
        {
          " each, and will not be burnt upon use! Immediately before the public release of Wasted Wild, which will take place "
        }
        <b style={{ fontWeight: "700" }}>
          {
            "within a month on a date and time TBD, mint pass holders will have at least 24 hours"
          }
        </b>
        {" to in turn mint the imaginary beings of the Wild, "}
        <b style={{ fontWeight: "700" }}>{"for free with guaranteed supply"}</b>
        {"."}
      </p>
    </>
  );
}
