import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: "AIzaSyDwzvQw-qQpV526v4TmyS0rlu_kN6P-aac",
  authDomain: "abar-94f37.firebaseapp.com",
  projectId: "abar-94f37",
  storageBucket: "abar-94f37.appspot.com",
  messagingSenderId: "640449925350",
  appId: "1:640449925350:web:91d93dbbba66678ee7eb27",
  measurementId: "G-NDN0WRGH7X",
};

const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(firebaseApp);

